/* urbanist-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/urbanist-v10-latin-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
       url('../fonts/urbanist-v10-latin-300.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* urbanist-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/urbanist-v10-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
       url('../fonts/urbanist-v10-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* urbanist-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Urbanist';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/urbanist-v10-latin-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
       url('../fonts/urbanist-v10-latin-italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* urbanist-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/urbanist-v10-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
       url('../fonts/urbanist-v10-latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
