@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* ------ Add your CSS below this line (or @import your other CSS files). ------- */

@import "../../node_modules/@splidejs/splide/dist/css/splide.min.css";
@import "modules/fonts";

@layer base {

}

    .splide__pagination__page.is-active {
        background: theme(colors.gray.600);
    }
